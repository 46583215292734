.bg-gradient {
	/* background: rgb(25, 24, 38); */
	background: linear-gradient(
		90deg,
		rgb(64, 64, 64) 20%,
		rgb(255, 255, 255) 100%
	);
}

.AlbumDetail--Editor--Container {
	display: flex;
	justify-content: left;
}

.album-form-container {
	/* display: flex; */
	/* justify-content: space-around; */
	margin-top: 20px;
	width: 200px;
	padding: 10px;
	border: solid 0.5px black;
}

.AlbumDetail--Editor--Card {
	/* display: flex; */
	/* flex-direction: column; */
	width: 100px;
}

.GridLayout--Container {
	/* padding: 0 60px; */
	margin-top: 10px;
	column-count: auto;
	column-width: 550px;
	column-fill: balance;
	/* gap: 1rem; */
	width: 1700px;
}

.photo-main-container {
	display: flex;
	justify-content: center;
	min-width: 1900px;
	background-color: #f3f5f6;
	margin-top: 20px;
	/* background-color: rgb(223, 220, 220); */
}

.PhotoDetail--Page {
	background-color: #f3f5f6;
	padding-bottom: 30px;
}

.full-width {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* width:100% */
}

.album-photo-container {
	display: flex;
	justify-content: center;
	background-color: #eaeaea;
	/* width:100%; */
}

.photo-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #eaeaea;
	width: 100%;
	min-width: 1900px;
}

.album-h1 {
	display: flex;
	justify-content: center;
}

/* @media (min-width: 480px) {
  .GridLayout--Container {
    padding: 0 120px;
    column-width: 180px;
  }
} */

/* @media (min-width: 768px) {
  .GridLayout--Container {
    padding: 0 80px;
    column-width: 240px;
  }
} */

/* @media (min-width: 1024px) {
  .GridLayout--Container {
    padding: 0 120px;
  }
} */

.GridLayout--Container .GridLayout--Item {
	display: inline-block;
	/* background: white; */
	/* border: 1px solid #aaa; */
	/* box-shadow: rgba(0,0,0,0.1) 2.5px 2.5px 2.5px; */
	/* border-radius: 0.75rem; */
	/* margin-bottom: 1rem; */
	overflow: hidden;
	position: relative;
	width: 100%;
}

.GridLayout--Item .GridLayout--Image {
	object-fit: cover;
	width: 100%;
	min-height: 120px;
}

.GridLayout--Item .GridLayout--Actions {
	/* left button positioning */
	position: absolute;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	top: 0;
	left: 0;
	padding: 10px;
	color: white;
	opacity: 0;
}

.GridLayout--Item .GridLayout--Actions:hover {
	opacity: 1;
}

.GridLayout--Item .GridLayout--Action--Content {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.GridLayout--ImageContainer {
	position: relative;
}

.GridLayout--Image {
	width: 100%;
	height: auto;
}

.GridLayout--Title {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: #128fdc;
	color: #fff;
	padding: 5px 8px;
	font-size: 15px;
	font-weight: 600;
	border-radius: 5px;
}
