* {
	font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
}

/* html {
	background-color: #f3f5f6
} */

.div-form-icon {
	display: flex;
	/* flex-direction: column; */
	justify-content: center;
}

.form-icon {
	/* display:flex;
	flex-direction: column;
	align-items: center; */
	height: 110px;
	width: 110px;
	background-color: hsl(0, 0%, 90%);
}

.album-title {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	gap: 0 5px;
}

.PhotoDetail--Image--Container {
	display: flex;
	justify-content: center;
	background-color: #212124;
	padding-top: 50px;
	padding-bottom: 50px;
	/* margin-left: 364px;
    margin-right: 364px; */
}

.home-main-container {
	display: flex;
	/* min-width:2300px; */
	justify-content: center;
	background-color: rgba(21, 21, 23, 0.95);
}

.main-container {
	min-width: 100%;
	/* height: 56px; */
	display: flex;
	justify-content: center;
	background-color: rgba(21, 21, 23, 0.95);
}

.navigation-bar {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	/* margin: 10px 10px; */
	gap: 20px;
	/* max-width: 1500px; */
	max-width: 100%;
	font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
	/* border: white solid; */
}

/* .navigation-bar {
	max-width: 1200px;
} */

.Nav-container {
	/* display: flex; */
	background-color: rgba(21, 21, 23, 0.95);
	justify-content: center;
	/* max-width: 1800px; */
	/* min-width: 1200px; */
	/* height: 56px; */
	min-width: 60%;
	/* border: blue solid; */
}
.nav_bar {
	background-color: rgba(21, 21, 23, 0.95);
	font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
	/* min-width: 1200px; */
}

.font-50 {
	font-size: 50px;
}

.nav-hover:hover {
	color: rgb(210, 210, 210);
}

.font-16 {
	font-size: 20px;
}
.font-decor-none {
	text-decoration: none;
}

.font-white {
	color: #fff;
}

.font-bold {
	font-weight: 400;
}

.left_side {
	display: flex;
	flex: 1;
	height: 48px;
	align-items: center;
	justify-content: left;
	gap: 30px;
	min-width: 600px;
	/* border: orange solid; */
}

.search_middle {
	flex: 2;
	height: 40px;
	display: flex;
	border-radius: 3px;
	overflow: hidden;
	align-items: center;
	border: 1px solid grey;
	background-color: #efefef;
	color: black;
	max-width: 500px;
}

.hidden_search {
	pointer-events: none;
	opacity: 0;
}

.search_middle input {
	background-color: transparent;
	border: none;
	max-width: 100%;
	font-size: 16px;
	padding: 0 20px;
}

.search_middle input:focus {
	outline: none;
}

.search_middle:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.right_side {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: right;
	gap: 10px;
}

.right_side img {
	width: 25px;
}

.dropdown_button {
	cursor: pointer;
	height: 100%;
	color: white;
	padding: 10px;
}

.dropdown-menu {
	cursor: auto;
	top: 50px;
	/* right:10px; */
	position: fixed;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	background-color: white;
	border-radius: 8px;
	padding: 10px 20px;
	/* width: 300px; */
	margin-top: 20px;
	z-index: 10;
}

.dropdown-menu::before {
	content: '';
	top: -5px;
	height: 20px;
	width: 20px;
	background: rgb(53, 49, 49);
	transform: rotate(45deg);
}

.dropdown-menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: 500ms ease;
}

.dropdown-menu.inactive {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: 500ms ease;
}

.dropdown-menu.active span {
	width: 24px;
	height: 1px;
	background: gray;
}

.menu_dropdown {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.menu_dropdown h5 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.menu_dropdown .create-button {
	align-self: flex-end;
}

.profile-width img {
	width: 40px;
}

.profile {
	display: flex;
	align-items: center;
}
.profile-hover:hover {
	cursor: pointer;
	background-color: lightgrey;
}

.profile h5 {
	font-style: italic;
	margin: 0;
}

.user_info {
	display: flex;
	margin-left: 20px;
	justify-content: center;
	flex-direction: column;
}
