.follow-container {
	display: flex;
	flex-direction: row;
	max-width: 1300px;
	max-height: 570px;
	flex-wrap: wrap;
}

.profile-image {
	max-width: 72px;
	max-height: 72px;
}

.follow-button {
	background-color: #f3f5f6;
	border: none;
	font-size: 16px;
	cursor: pointer;
}

.follow-button:hover {
	color: darkgrey;
}
