
.profile-container {
	display: flex;
	/* justify-content: flex-start; */
	border: solid 1px lightgrey;
	max-width: fit-content;
	min-width: min-content;
	margin-bottom: 5px;
}
.profile-img2 {
	border-radius: 50%;
	margin-right: 15px;
	width: 30px;
}

.Author {
	display: flex;
	font-size: 25px;
	color: black;
	text-decoration: none;
	justify-content: space-between;
	align-items: center;
	min-width: 125px;
}

.Author:hover {
	color: #006dac;
	text-decoration: underline;
	font-weight: bold;
}

.Author-Name {
	display: flex;
}
.container {
	position: relative;
	/* top: 77%; */
	/* left: 13%; */
	/* transform: translate(-50%, -50%); */
	/* background-color: #eceff1; */
	width: 500px;
	height: 250px;
	border-radius: 10px;
	overflow: hidden;
}

/* .menu-icon {
	position: absolute;
	right: 0;
	width: 53px;
	height: 53px;
	filter: invert(40%) sepia(57%) saturate(2228%) hue-rotate(189deg)
		brightness(96%) contrast(87%);
} */

/* .svg-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1E88E5;
    -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
    clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  } */

/* .svg-background2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 20px;
    background-color: rgba(0,0,0,0.12);
    z-index: -9;
    -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
    clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  } */

.profile-img {
	position: absolute;
	width: 150px;
	height: 150px;
	margin-top: 55px;
	margin-left: 40px;
	border-radius: 50%;
}

.circle {
	position: absolute;
	width: 162px;
	height: 161px;
	left: 0;
	top: 0;
	/* border: solid 1px black; */
	background-color: #eceff1;
	border-radius: 50%;
	margin-top: 50.5px;
	margin-left: 35px;
}

.text-container {
	position: absolute;
	right: 0;
	margin-right: 40px;
	margin-top: 45px;
	max-width: 235px;
	text-align: left;
}

.title-text {
	color: #263238;
	font-size: 16px;
	font-weight: 600;
	margin-top: 5px;
}

.info-text {
	margin-top: 10px;
	font-size: 18px;
}

.desc-text {
	font-size: 14px;
	margin-top: 10px;
}
