.comment-input {
	border-color: #9ea4a7;
	min-width: 50%;
	max-width: 330px;
	min-height: 50px;
	max-height: 100px;
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 20px;
}
.comment-input:hover {
	border-color: #006dac;
}
.comment-input:focus {
	border-color: #006dac;
}

.comment-author {
	color: #006dac;
	font-weight: 400;
	font-size: 16px;
	text-decoration: None;
}

.comment-author:hover {
	color: #006dac;
	text-decoration: underline;
	font-weight: bold;
}

.item2 {
	margin-top: 40px;
}

.star-button {
	width: 40px;
	margin-left: 5px;
}
.num-label {
	font-size: 14px;
	color: #898989;
}

.num-count {
	font-size: 20px;
	color: #212124;
}

.photo-detail-button-container {
	/* border: solid orange 1px; */
	display: flex;
	justify-content: center;
	margin-right: 340px;
	gap: 5px;
	margin-top: 10px;
}

.g-flex-container {
	display: flex;
	justify-content: space-around;
}

.g-container {
	display: grid;
	min-width: 1200px;
	max-width: 100%;
	min-height: 330px;
	max-height: 100%;
	grid-template-columns: 0.4fr 0.3fr 1fr;
	/* grid-template-columns: 1fr 1fr 1fr 1fr; */
	grid-auto-rows: min-content;
}

.g2-container {
	display: grid;
	grid-auto-rows: min-content;
	width: 250px;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
}

.list-comments {
	margin-top: 10px;
}

.comment-actions {
	display: flex;
	align-items: center;
}

.comment-image {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid lightgrey;
	/* object-fit: cover; */
	margin-right: 10px;
}

.comment-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	justify-content: left;
	margin-bottom: 2px;
}

.trash-icon,
.edit-icon {
	opacity: 0.2;
	width: 20px;
	height: 20px;
}

.trash-icon:hover,
.edit-icon:hover {
	opacity: 0.8;
	cursor: pointer;
}

.comment-font {
	font-size: 12px;
	color: #212124;
}
.comments-box {
	/* border: solid orange; */
	grid-auto-rows: min-content;
	overflow-y: auto;
	height: 600px;
	min-width: 300px;
}

.user-name {
	display: block;
	font-size: 14px;
	color: #006dac;
}
.user-comment {
	display: flex;
	/* min-height: 20px; */
	align-items: center;
	/* justify-content: space-between; */
	min-width: 70%;
	color: #212124;
	font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
	padding: 2px;
}
